import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

export type HeadProps = {
  title?: string;
  description?: string;
  imageUrl?: string;
  path?: string;
  children?: ReactNode;
  noindex?: boolean;
};

export default function Head({
  title,
  description = 'Motivo Learning is a subscription-based, approved CE provider with live and on-demand courses covering therapeutic methods and topics such as access, equity, and cultural humility.',
  imageUrl = `${process.env.NEXT_PUBLIC_BASE_URL}/assets/ogimage.png`,
  path,
  children,
  noindex = false,
}: HeadProps) {
  const router = useRouter();
  const url = `${process.env.NEXT_PUBLIC_BASE_URL}${path || router.asPath}`;

  const metaTitle = `${title ? `${title} | ` : ''}Motivo Learning`;
  return (
    <NextHead>
      <title key="title">{metaTitle}</title>
      <meta name="description" content={description} key="description" />
      <link rel="canonical" href={url} key="canonical" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" key="viewport" />
      <meta charSet="utf-8" key="charset" />

      <meta property="og:type" content="website" key="ogType" />
      <meta property="og:title" content={metaTitle} key="ogTitle" />
      <meta property="og:description" content={description} key="ogDescription" />
      <meta property="og:url" content={url} key="ogUrl" />
      <meta property="og:image" content={imageUrl} key="ogImage" />

      <meta name="twitter:title" content={metaTitle} key="twitterTitle" />
      <meta name="twitter:description" content={description} key="twitterDescription" />
      <meta name="twitter:site" content="@motivohealth" key="twitterSite" />
      <meta name="twitter:image" content={imageUrl} key="twitterImage" />

      {noindex && <meta name="robots" content="noindex" key="robots" />}

      {children}
    </NextHead>
  );
}
