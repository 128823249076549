import * as Sentry from '@sentry/nextjs';
import useViewer from './useViewer';

export default function useLogger() {
  const { viewer } = useViewer();

  if (viewer?.id && viewer?.email) {
    Sentry.setUser({ id: String(viewer.id), email: viewer.email });
  } else {
    Sentry.setUser(null);
  }

  return (exception: any) => {
    Sentry.captureException(exception);
  };
}
