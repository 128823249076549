import { Link, LinkProps, styled } from '@mui/material';
import NextLink from 'next/link';

export const LINK_COLOR = '#0070D7';

const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'inheritColor',
})<{ inheritColor?: boolean }>(({ inheritColor }) => ({
  color: inheritColor ? 'inherit' : LINK_COLOR,
}));

export type MoLinkProps = {
  href: string;
  inheritColor?: boolean;
};

export default function MoLink({
  href,
  children,
  inheritColor = true,
  ...restProps
}: LinkProps & MoLinkProps) {
  const isExternalLink = href.startsWith('http');

  if (isExternalLink) {
    return (
      <StyledLink
        {...restProps}
        inheritColor={inheritColor}
        href={href}
        target="_blank"
        rel="noreferrer nofollow"
      >
        {children}
      </StyledLink>
    );
  }

  return (
    <NextLink href={href} passHref legacyBehavior>
      <StyledLink {...restProps} inheritColor={inheritColor}>
        {children}
      </StyledLink>
    </NextLink>
  );
}
