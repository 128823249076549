import { viewerQuery } from ':src/apollo/auth/queries';
import { useSuspenseQuery } from '@apollo/client';

export default function useViewer() {
  const {
    data: { viewer },
  } = useSuspenseQuery(viewerQuery);

  return { viewer };
}
