import type { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { UserProvider } from '@auth0/nextjs-auth0';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Head from ':src/components/Head';
import Layout from ':src/components/Layout';
import { ThemeProvider } from ':src/ThemeProvider';
import { useApollo } from ':src/apollo/apolloClient';
import createEmotionCache from ':src/utils/emotion';
import { StyledSnackbarProvider } from ':src/StyledSnackbarProvider';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || '', {
  stripeAccount: process.env.NEXT_PUBLIC_STRIPE_ACCOUNT,
});

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const handlePageView = (url: string, { shallow }: { shallow: boolean }) => {
  if (!shallow) {
    window.gtag('set', 'page_path', url);
    window.gtag('event', 'page_view');
  }
};

function Athena({ Component, pageProps, emotionCache = clientSideEmotionCache }: MyAppProps) {
  const apolloClient = useApollo(pageProps);
  const router = useRouter();
  const hideLayout = router.pathname.startsWith('/join-course/');

  useMemo(() => {
    router.prefetch = async () => {};
  }, [router]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production' || !apolloClient) {
      return undefined;
    }

    router.events.on('routeChangeComplete', handlePageView);

    return () => {
      router.events.off('routeChangeComplete', handlePageView);
    };
  }, [router.events, apolloClient]);

  if (!apolloClient) {
    if (typeof window !== 'undefined') window.location.reload();
    return null;
  }

  return (
    <UserProvider>
      {/* @ts-ignore */}
      <Elements stripe={stripePromise}>
        <CacheProvider value={emotionCache}>
          <Head />
          <ThemeProvider>
            <StyledSnackbarProvider maxSnack={1}>
              <ApolloProvider client={apolloClient}>
                {hideLayout ? (
                  <Component {...pageProps} />
                ) : (
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                )}
              </ApolloProvider>
            </StyledSnackbarProvider>
          </ThemeProvider>
        </CacheProvider>
      </Elements>
    </UserProvider>
  );
}
export default Athena;
