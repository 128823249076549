import { ReactNode } from 'react';
import { styled, Box } from '@mui/material';
import { useRouter } from 'next/router';
import useLogger from ':src/hooks/useLogger';
import Header from './Header';
import Footer from './Footer';

const ContentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasGreyBackground',
})<{ hasGreyBackground: boolean }>(({ theme, hasGreyBackground }) => ({
  flexGrow: 1,
  padding: `${theme.spacing(4)} 0`,
  ...(hasGreyBackground
    ? {
        backgroundColor: '#F4F2F6',
      }
    : {
        borderBottom: '1px solid #C7C7C7',
      }),

  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(8)} 0`,
  },
}));

export type LayoutProps = {
  children: ReactNode;
};

export default function Layout({ children }: LayoutProps) {
  useLogger();
  const { pathname } = useRouter();
  const hasGreyBackground = pathname === '/courses' || pathname === '/';
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <ContentWrapper hasGreyBackground={hasGreyBackground}>{children}</ContentWrapper>
      <Footer />
    </Box>
  );
}
