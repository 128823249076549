import React, { ReactNode } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import { publicMotivoTheme } from './theme/publicMotivoTheme';

const globalStyles = (
  <GlobalStyles
    styles={{
      html: {
        '-webkit-font-smoothing': 'auto',
      },
      body: {
        fontFamily: ['proxima-nova', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        fontWeight: 500,
        color: '#444243',
        margin: 0,
      },
      a: {
        textDecoration: 'none',
        color: 'unset',
      },
      strong: {
        fontWeight: 600,
      },

      '.StripeElement': {
        padding: '16px',
        border: '1px solid #BBB9BA',
        borderRadius: '4px',
      },
      '.StripeElement--focus': {
        padding: '15px',
        border: '2px solid #30A08F',
      },
      '.StripeElement--invalid': {
        borderColor: '#E46252 !important',
      },
      '.SnackbarContainer-root': {
        width: 'unset !important',
      },
      '.SnackbarItem-formSaved': {
        minWidth: 'unset !important',
        width: 'fit-content',
        backgroundColor: '#1A8F7D !important',
        fontWeight: '700 !important' as any,
      },
    }}
  />
);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <MuiThemeProvider theme={publicMotivoTheme}>
      <CssBaseline />
      {globalStyles}
      {children}
    </MuiThemeProvider>
  );
};
